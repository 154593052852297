var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? _c('LoaderReport') : _c('layout-report-with-banner', {
    attrs: {
      "isProgressDone": true,
      "showFooter": false,
      "showLoginButton": true
    },
    scopedSlots: _vm._u([{
      key: "banner",
      fn: function fn() {
        return [_c('v-img', {
          attrs: {
            "src": _vm.headerImage,
            "height": "550"
          }
        }, [_c('v-overlay', {
          attrs: {
            "value": "true",
            "absolute": ""
          }
        }, [_c('v-container', {
          staticClass: "mx-auto"
        }, [_c('v-row', {
          staticClass: "align-left justify-left"
        }, [_c('v-col', {
          staticClass: "ml-4"
        }, [_c('h2', {
          staticClass: "text-md-h2 text-sm-h3 text-h3 font-weight-medium mt-sm-8 mt-md-0",
          domProps: {
            "innerHTML": _vm._s(_vm.title)
          }
        }), _vm.subtitle ? _c('h2', {
          staticClass: "text-md-h4 text-sm-h5 text-h5 font-weight-medium mt-md-3",
          domProps: {
            "innerHTML": _vm._s(_vm.subtitle)
          }
        }) : _vm._e()])], 1), _c('v-row', {
          staticClass: "mt-8 align-left justify-left"
        }, [_c('v-col', {
          attrs: {
            "xl": "4",
            "lg": "5",
            "md": "6",
            "xs": "10",
            "sm": "8",
            "cols": "10"
          }
        }, [_c('v-btn', {
          staticClass: "ml-4",
          attrs: {
            "color": _vm.color,
            "id": "start",
            "elevation": "0",
            "block": "",
            "dark": "",
            "x-large": "",
            "data-test-thg-start-1": ""
          },
          on: {
            "click": _vm.start
          }
        }, [_vm._v(" " + _vm._s(_vm.submitButtonText) + " ")])], 1)], 1), _c('div', {
          staticClass: "text-caption mb-4 mt-4 mx-4"
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-shield-check")]), _vm._v(_vm._s(_vm.$t("report.thgHome.dsgvo")) + " "), _c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-lock")]), _vm._v(_vm._s(_vm.$t("report.thgHome.encryption")) + " "), _c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-check-decagram")]), _vm._v(" " + _vm._s(_vm.$t("report.thgHome.datacenter")) + " ")], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('v-row', {
          attrs: {
            "align": "center",
            "justify": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('v-card', {
          attrs: {
            "flat": "",
            "max-width": "800"
          }
        }, [_c('v-card-text', {
          staticClass: "text-md-h2 text-sm-h3 text-h3 mt-md-12  mt-4  font-weight-medium"
        }, [_c('h1', {
          style: "color:".concat(_vm.partnerColorDarkmode),
          attrs: {
            "data-test-thg-title": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("report.thgHome.title")) + " ")])])], 1), _c('v-card', {
          attrs: {
            "flat": "",
            "max-width": "600"
          }
        }, [_c('v-card-text', {
          staticClass: "text-md-h6 text-sm-h6 text-body-2 font-weight-regular",
          domProps: {
            "innerHTML": _vm._s(_vm.subTitle)
          }
        }), _c('v-card-text', {
          staticClass: "text-md-h6 text-sm-h6 text-body-2 font-weight-regular",
          domProps: {
            "innerHTML": _vm._s(_vm.subTitle2)
          }
        })], 1), _c('v-card', {
          attrs: {
            "flat": "",
            "max-width": "600"
          }
        }, [!_vm.isWhiteLabelPartner ? _c('v-card-text', {
          staticClass: "text-caption",
          domProps: {
            "innerHTML": _vm._s(_vm.$t('report.thgImpactFactor.note'))
          }
        }) : _c('v-card-text', {
          staticClass: "text-caption",
          domProps: {
            "innerHTML": _vm._s(_vm.$t('report.thgImpactFactor.noteWhiteLabelPartner'))
          }
        }), _c('v-row', {
          staticClass: "ma-4 mt-8"
        }, [_c('login-register-button', {
          attrs: {
            "showRegisterButton": false,
            "htmlClass": "mr-2",
            "data-test-thg-start-2": ""
          }
        }), _c('v-btn', {
          attrs: {
            "color": _vm.color,
            "id": "start",
            "elevation": "0",
            "dark": "",
            "large": "",
            "data-test-report-start": ""
          },
          on: {
            "click": _vm.start
          }
        }, [_vm._v(" " + _vm._s(_vm.submitButtonText) + " ")])], 1)], 1)], 1), _c('v-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('v-img', {
          staticClass: "ma-8",
          attrs: {
            "src": _vm.placeFillerImage,
            "lazy-src": _vm.placeFillerImageLazy
          }
        })], 1)], 1), _vm.showTrustBanner ? _c('known-form-card', {
          staticClass: "mt-8",
          attrs: {
            "headline": _vm.knownFromText,
            "logos": _vm.logos
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }