










































































































import CreateOrUpdateBankDialog from "@/components/profile/CreateOrUpdateBankDialog.vue";
import EditProfileDialog from "@/components/profile/EditProfileDialog.vue";
import { UpdateUserDtoFactory } from "@/lib/utility/updateUserDtoFactory";
import { ContactEntity } from "@/models/contactEntity";
import { IUser } from "@/models/user.entity";
import { ThgBankingDtoGen } from "@/services/thg/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { BankingModule } from "@/store/modules/banking.store";
import { ConfigModule } from "@/store/modules/config";
import { UserModule } from "@/store/modules/me-user.store";
import { Component, Vue, Ref } from "vue-property-decorator";
import LoginCardAuthorizationOverview from "../login/LoginCardAuthorizationOverview.vue";
import ProfileBankingForm from "./ProfileBankingForm.vue";
import ProfileForm from "./ProfileForm.vue";
import ProfileNotificationForm from "./ProfileNotificationForm.vue";
import { MrfiktivCustomerAccountViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { CustomerAccountMeModule } from "@/store/modules/customer-account-me.store";
import { IAddress } from "@/models/address.entity";

enum ProfileTabsEnum {
  PROFILE = "profile",
  BANKING = "banking",
  NOTIFICATION = "notification",
  PERMISSION = "permission"
}

@Component({
  components: {
    ProfileBankingForm,
    CreateOrUpdateBankDialog,
    EditProfileDialog,
    ProfileForm,
    ProfileNotificationForm,
    LoginCardAuthorizationOverview
  }
})
export default class ProfileCard extends Vue {
  readonly tabs = [
    ProfileTabsEnum.PROFILE,
    ProfileTabsEnum.BANKING,
    ProfileTabsEnum.NOTIFICATION,
    ProfileTabsEnum.PERMISSION
  ];
  debug = this.$store.state.config.debug;
  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;
  isLoadingBanking = false;
  isUpdatingUser = false;
  isSystemEmail = true;
  tab = this.preselectedTab;

  isProfileFormDisabled = true;
  isBankingFormDisabled = true;
  isNotificationFormDisabled = true;

  get ProfileTabsEnum() {
    return ProfileTabsEnum;
  }

  get preselectedTab(): ProfileTabsEnum {
    const preselected = this.$route.params.tab as ProfileTabsEnum;

    if (!preselected) {
      return ProfileTabsEnum.PROFILE;
    }

    return preselected;
  }

  @Ref("profileForm")
  form!: ProfileForm;

  updateFormDisabled(v: boolean) {
    this.isProfileFormDisabled = v;
  }

  startProfileEdit() {
    this.form.initialize();
    this.isProfileFormDisabled = false;
  }

  startBankingEdit() {
    this.isBankingFormDisabled = false;
  }

  startNotificationEdit() {
    this.isNotificationFormDisabled = false;
  }

  mounted() {
    /**
     * Fetch Banking Data only if BankingService is available and no current banking data fetched (this is done on login event in thg app)
     */
    if (ConfigModule.isBankingAvailable && BankingModule.myBanking.iban === "") {
      try {
        this.isLoadingBanking = true;
        BankingModule.getMyBanking();
      } catch (error) {
        this.$toast.error("Bankinformationen konnten nicht geladen werden.");
      } finally {
        this.isLoadingBanking = false;
      }
    }
  }

  get user(): IUser {
    return UserModule.user;
  }

  get customerAccount(): MrfiktivCustomerAccountViewModelGen | null {
    return CustomerAccountMeModule.currentAccount;
  }

  get partnerNotifications() {
    return this.customerAccount?.isMarketingOptIn ?? false;
  }

  set partnerNotifications(val: boolean) {
    if (!this.customerAccount) {
      return;
    }

    this.customerAccount.isMarketingOptIn = val;
  }

  get isPartnerNotificationsEnabled() {
    return CustomerAccountMeModule.isEnabled;
  }

  get isBankingAvailable(): boolean {
    return ConfigModule.isBankingAvailable;
  }

  get contact(): ContactEntity {
    return UserModule.user.contact || ({ email: "", phone: "" } as ContactEntity);
  }

  get banking(): ThgBankingDtoGen {
    return BankingModule.myBanking;
  }

  get address(): IAddress {
    return UserModule.user.address;
  }

  logout() {
    UserModule.logout();
  }

  goBack() {
    this.$router.go(-1);
  }

  edit() {
    this.$router.push({ name: "ProfileEdit" });
  }

  async updateBanking() {
    this.isLoadingBanking = true;
    try {
      const { bank, iban, name } = this.banking;
      await BankingModule.createOrUpdateMyBanking({
        bank,
        iban,
        name
      });
    } catch (error) {
      this.$toast.error("Bankverbindung konnte nicht geändert werden.");
    } finally {
      this.isLoadingBanking = false;
      this.isBankingFormDisabled = true;
    }
  }

  async updateUser(user: IUser) {
    try {
      this.isUpdatingUser = true;
      const updateUserDto = new UpdateUserDtoFactory().createFromIUser(user);
      await UserModule.updateUser({ id: this.user._id, updateUserDto: updateUserDto });
      this.$toast.success(this.$t("components.profile.EditProfileDialog.success"));
    } catch (error) {
      this.form.initialize();
      this.$log.error(error);
      this.$toast.error(this.$t("components.profile.EditProfileDialog.errorMsg"));
    } finally {
      this.isUpdatingUser = false;
      this.isProfileFormDisabled = true;
    }
  }

  async updateNotification() {
    try {
      this.isUpdatingUser = true;
      const updateUserDto = new UpdateUserDtoFactory().createFromIUser(this.user);
      await UserModule.updateUser({
        id: this.user._id,
        updateUserDto: {
          ...updateUserDto,
          isMarketingOptIn: this.user.isMarketingOptIn
        }
      });
      this.$toast.success(this.$t("components.profile.EditProfileDialog.success"));
    } catch (error) {
      this.form.initialize();
      this.$toast.error(this.$t("components.profile.EditProfileDialog.errorMsg"));
    }

    if (this.isPartnerNotificationsEnabled && this.customerAccount) {
      try {
        await CustomerAccountMeModule.update({
          isMarketingOptIn: !!this.customerAccount?.isMarketingOptIn
        });
      } catch (error) {
        Vue.$log.error(error);
      }
    }
    this.isUpdatingUser = false;
    this.isNotificationFormDisabled = true;
  }
}
