import i18n from "@/plugins/I18nPlugin";
import { CountryCodeEnum } from "./enum/country-code.enum";

/**
 * Helper for auto complete props
 */
interface IAutocomplete {
  text: string;
  value: string;
}

/**
 * Translates country codes to full names
 */
export const autoCompleteCountryCodes: IAutocomplete[] = Object.keys(CountryCodeEnum).map(k => ({
  value: CountryCodeEnum[k as any],
  text: i18n.tc(`country.${CountryCodeEnum[k as any] || CountryCodeEnum.germany}`)
}));

/**
 * Gets a flag emoji based on country code string
 *
 * @param countryCode the country code to get flag for
 * @returns a flag based on country code e.g. "DE" -> 🇩🇪
 */
export function getFlagEmoji(countryCode: string) {
  const codePoints = countryCode
    .toUpperCase()
    .split("")
    .map(char => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}
