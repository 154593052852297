










































































































import { AssetEnum, AssetRepository } from "@/lib/AssetRepository";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { NumberplateModule } from "@/store/modules/numberplateStore";
import { Component, Prop, Ref, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class NumberplateForm extends DarkModeHighlightMixin {
  @Prop()
  value!: string;

  @Prop({ default: false })
  valid!: string;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: CountryCodeEnum.germany })
  countryCode!: CountryCodeEnum;

  @Ref("letterField")
  private readonly letterField!: HTMLElement;

  @Ref("numberField")
  private readonly numberField!: HTMLElement;

  @Ref("form")
  private readonly form!: Vue & { validate: () => boolean };

  get isGermanNumberplate(): boolean {
    return this.countryCode == CountryCodeEnum.germany;
  }

  @Watch("value")
  onValueChange(value: string) {
    this.$log.debug(value);
    this.privateNumberplate = value;
    this.tryParseNumberplate();
  }

  @Watch("privateNumberplate", { deep: true })
  emitValid() {
    if (this.specialFormat) {
      this.$emit("valid", !!this.privateNumberplate.length);

      return;
    }

    if (this.privateCity.length && this.privateLetters.length && this.privateNumbers.length) {
      this.$emit("valid", true);
    } else {
      this.$emit("valid", false);
    }
  }

  @Watch("privateNumberplate", { deep: true })
  emitUpdated() {
    this.$emit("input", this.privateNumberplate);
  }

  privateNumberplate = this.value;
  interalValid = this.valid;

  onkeydownCity(event: KeyboardEvent) {
    if (!/[a-zA-ZÄÖÜäöü]/i.test(event.key)) {
      event.preventDefault();
      return false;
    }
  }

  onkeydown(event: KeyboardEvent) {
    if (!/[a-zA-Z]/i.test(event.key)) {
      event.preventDefault();
      return false;
    }
  }

  /**
   * is the numberplate a speical format?
   */
  get specialFormat(): boolean {
    return NumberplateModule.isSepcialFormat;
  }

  /**
   * set special format flag to true if:
   * numberplate from query is not parsable
   * or user clicks on special format
   */
  set specialFormat(value: boolean) {
    NumberplateModule.setSepcialFormat(value);
  }

  disableFocusLetters = false;
  disableFocusNumbers = false;

  privateCity = "";
  privateLetters = "";
  privateNumbers = "";

  toggle() {
    this.numberPlate = "";
    this.privateNumberplate = "";
    this.privateCity = "";
    this.privateLetters = "";
    this.privateNumbers = "";
    this.specialFormat = !this.specialFormat;
  }

  get numberplateImageGerman() {
    return AssetRepository.getAsset(this.$vuetify.theme.dark, AssetEnum.numberplateEuropeDe);
  }

  get numberplateImageGeneric() {
    return AssetRepository.getAsset(this.$vuetify.theme.dark, AssetEnum.numberplateEuropeGeneric);
  }

  get numberplateBadge() {
    return AssetRepository.getAsset(this.$vuetify.theme.dark, AssetEnum.numberplateBadge);
  }

  get city(): string {
    return this.privateCity;
  }

  set city(city: string) {
    this.privateCity = city;
  }

  get letters(): string {
    return this.privateLetters;
  }
  set letters(letters: string) {
    this.privateLetters = letters;
  }

  get numbers(): string {
    return this.privateNumbers;
  }
  set numbers(numbers: string) {
    this.privateNumbers = numbers;
  }

  get numberPlate(): string {
    return this.privateNumberplate;
  }

  set numberPlate(numberplate: string) {
    this.privateNumberplate = numberplate;
    this.emitUpdated();
  }

  setNumberPlate() {
    this.privateNumberplate =
      this.privateCity.toUpperCase().trim() +
      "-" +
      this.privateLetters.toUpperCase().trim() +
      " " +
      this.privateNumbers.toUpperCase().trim();
  }

  keyUpCityField() {
    this.privateCity = this.city.toUpperCase();
    this.privateCity = this.privateCity.trim();
    this.setNumberPlate();
    if (this.city.length <= 2 || this.disableFocusLetters) {
      return;
    }
    this.disableFocusLetters = true;
    setTimeout(() => {
      this.letterField.focus();
      this.form.validate();
    }, 50);
  }

  keyUpLetterField() {
    this.privateLetters = this.privateLetters.toUpperCase();
    this.privateLetters = this.privateLetters.trim();
    this.setNumberPlate();
    if (this.privateLetters.length <= 1 || this.disableFocusNumbers) {
      return;
    }
    this.disableFocusNumbers = true;
    setTimeout(() => {
      this.numberField.focus();
      this.form.validate();
    }, 50);
  }

  keyUpNumberField() {
    /**
     * Transform to Uppercase
     */
    this.privateNumbers = this.privateNumbers.toUpperCase();

    /**
     * Divide String into Alpha and digits
     */
    let digits = this.privateNumbers.replace(/[^0-9]/g, "");
    const spaces = this.privateNumbers.replace(/[^ ]/g, "");
    let alpha = this.privateNumbers.replace(/[^EehH]/g, "");

    /**
     * Only allow 4 digits
     */
    if (digits.length > 4) {
      digits = digits.slice(0, 4);
    }

    /**
     * Check if string contains characters
     */
    if (alpha.length) {
      /**
       * If more than one character only use first one since numberplate can contains only E or H
       */
      if (alpha.length > 1) {
        alpha = alpha.slice(0, 1);
      }

      this.privateNumbers = digits + " " + alpha;
    } else if (digits.length && spaces.length) {
      this.privateNumbers = digits + " ";
    } else {
      this.privateNumbers = digits;
    }

    this.setNumberPlate();
  }

  keyUpSpecialNumberplate() {
    this.numberPlate = this.numberPlate.toUpperCase();
    this.emitUpdated();
  }
  tryParseNumberplate() {
    this.$log.debug("tryParseNumberplate", this.numberPlate);
    let numberplateStringParts = this.numberPlate.split(/-| |_/);

    numberplateStringParts = numberplateStringParts.filter(str => str !== "");

    if (this.specialFormat === true) {
      this.keyUpSpecialNumberplate();

      return;
    }

    if (numberplateStringParts.length === 0) {
      return;
    } else if (
      numberplateStringParts.length >= 3 &&
      numberplateStringParts[0].length <= 3 &&
      numberplateStringParts[1].length <= 2 &&
      numberplateStringParts[2].length <= 6 &&
      numberplateStringParts[0].match(new RegExp(/[a-zA-ZÄÖÜäöü]/)) &&
      numberplateStringParts[1].match(new RegExp(/[a-zA-Z]/))
    ) {
      this.specialFormat = false;
      this.privateCity = numberplateStringParts[0].toUpperCase();
      this.privateLetters = numberplateStringParts[1].toUpperCase();

      this.privateNumbers = numberplateStringParts[2].toUpperCase();
      if (numberplateStringParts.length > 3) {
        for (let i = 3; i < numberplateStringParts.length; i++) {
          this.privateNumbers = this.privateNumbers + " " + numberplateStringParts[i].toUpperCase();
        }
      }

      this.setNumberPlate();
    } else if (
      numberplateStringParts.length === 2 &&
      numberplateStringParts[0].length <= 3 &&
      numberplateStringParts[1].length <= 2 &&
      numberplateStringParts[0].match(new RegExp(/[a-zA-ZÄÖÜäöü]/)) &&
      numberplateStringParts[1].match(new RegExp(/[a-zA-Z]/))
    ) {
      this.specialFormat = false;
      this.privateCity = numberplateStringParts[0].toUpperCase();
      this.privateLetters = numberplateStringParts[1].toUpperCase();
    } else if (
      numberplateStringParts.length === 1 &&
      numberplateStringParts[0].length <= 3 &&
      numberplateStringParts[0].match(new RegExp(/[a-zA-ZÄÖÜäöü]/))
    ) {
      this.specialFormat = false;
      this.privateCity = numberplateStringParts[0].toUpperCase();
    } else {
      this.keyUpSpecialNumberplate();
      this.specialFormat = true;
    }
  }

  mounted() {
    try {
      this.privateNumberplate = this.value;

      if (!this.isGermanNumberplate) {
        this.keyUpSpecialNumberplate();
        this.specialFormat = true;
        this.emitValid();
        return;
      }

      this.tryParseNumberplate();
      this.emitValid();
    } catch (error) {
      this.$log.error(error);
    }
  }
}
