


















































































































































































































































































































































































































































































































































































































import LayoutReport from "@/layouts/LayoutReport.vue";
import { Component, Vue } from "vue-property-decorator";
import { ConfigModule } from "@/store/modules/config";

@Component({
  components: { LayoutReport }
})
export default class ThgTerms extends Vue {
  get isProd() {
    return ConfigModule.isProd;
  }
}
