


































































import ThgCreateOverviewCard from "@/components/thg/ThgCreateOverviewCard.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { requiredRule } from "@/lib/rules/requiredRule";
import { Type } from "@/lib/utility/type";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ReportModule } from "@/store/modules/report.store";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { UserModule } from "@/store/modules/me-user.store";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    LayoutReportStep,
    ThgCreateOverviewCard
  }
})
export default class ThgClosingCard extends Vue {
  @Prop()
  loading!: boolean;

  @Prop()
  dialogCloseButtonFunction?: Function;

  @Prop({ default: false })
  hideTitle?: boolean;

  @Prop({ default: "" })
  hint?: string;

  @Prop({ default: false })
  hideEdit?: boolean;

  @Prop({ default: false })
  hideSubmit?: boolean;

  @Prop({ default: false })
  userExists?: boolean;

  get isLoading() {
    return this.loading;
  }

  set isLoading(n: boolean) {
    this.$emit("update:loading", n);
  }

  header: IReportHeaderData = {
    title: "report.thgClosing.title",
    description: "report.thgClosing.description"
  };

  mounted() {
    this.isConfirmed = false;
  }

  get years() {
    return ThgCreateModule.years;
  }

  get isCheckoutCardShowing() {
    return true;
  }

  get privacyPolicyLink() {
    return "https://www.wirkaufendeinethg.de/datenschutz/";
  }

  get termsLink() {
    return "https://www.wirkaufendeinethg.de/agbs/";
  }

  get campaign(): any {
    return ThgCreateModule.campaign;
  }

  get code(): any {
    return ThgCreateModule.code;
  }

  valid = false;

  userExistsText = "report.thgClosing.userAlreadyExists";

  get isDone() {
    return this.valid && this.isConfirmed;
  }

  get form(): Vue & { validate: () => boolean } {
    return this.$refs.form as Vue & { validate: () => boolean };
  }

  get reportReviewedRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(this.thgRegistrationImageValidRule());
    rules.push(this.numberplateIsMandatory());

    //TODO: Make this ways smarter
    if (ReportModule.screenOder.includes(ReportScreenEnum.thgcontact) && !this.isAuthenticated) {
      rules.push(this.reportContactEmailValidRule());
      rules.push(this.reportUserValidRule());
    }

    if (ReportModule.screenOder.includes(ReportScreenEnum.thgpassword) && !this.isAuthenticated) {
      rules.push(this.passwordIsMandatory());
    }

    return rules;
  }

  get isAuthenticated() {
    return UserModule.isAuthenticated;
  }

  passwordIsMandatory(message = "report.thgClosing.passwordMandatory"): boolean | string {
    const passwordValid = Type.isPresent(ThgCreateModule.password) && !Type.isBlankString(ThgCreateModule.password);
    return passwordValid || String(this.$t(message));
  }

  numberplateIsMandatory(message = "report.thgClosing.numberplateMandatory"): boolean | string {
    const numberplateValid =
      Type.isPresent(ThgCreateModule.numberplate) && !Type.isBlankString(ThgCreateModule.numberplate);
    return numberplateValid || String(this.$t(message));
  }

  reportContactPhoneValidRule(message = "report.summary.phoneMandatory"): boolean | string {
    return (
      (Type.isPresent(ThgCreateModule.contact.phone) && !Type.isBlankString(ThgCreateModule.contact.phone)) ||
      String(this.$t(message))
    );
  }

  reportContactEmailValidRule(message = "report.summary.mailMandatory"): boolean | string {
    return (
      (Type.isPresent(ThgCreateModule.contact.email) && !Type.isBlankString(ThgCreateModule.contact.email)) ||
      String(this.$t(message))
    );
  }

  thgRegistrationImageValidRule(message = "report.thgClosing.registrationImagesMandatory"): boolean | string {
    return ThgCreateModule.registrationImageFront.length !== 0 || String(this.$t(message));
  }

  reportUserValidRule(message = "report.summary.nameMandatory"): boolean | string {
    return (
      (Type.isPresent(ThgCreateModule.user.firstName) &&
        Type.isPresent(ThgCreateModule.user.lastName) &&
        !Type.isBlankString(ThgCreateModule.user.firstName) &&
        !Type.isBlankString(ThgCreateModule.user.lastName)) ||
      String(this.$t(message))
    );
  }

  get isTermsAccepted(): boolean {
    return ThgCreateModule.isTermsAccepted;
  }

  set isTermsAccepted(bool: boolean) {
    ThgCreateModule.setIsTermsAccepted(bool);
  }

  get isConfirmed(): boolean {
    return ThgCreateModule.isConfirmed;
  }

  set isConfirmed(bool: boolean) {
    ThgCreateModule.setIsConfirmed(bool);
  }

  get isMarketingContactAllowed(): boolean {
    return ThgCreateModule.isMarketingOptIn;
  }

  set isMarketingContactAllowed(bool: boolean) {
    ThgCreateModule.setIsMarketingOptIn(bool);
  }

  goToLogin() {
    this.$router.push({ path: "/login" });
  }

  async submit() {
    this.$emit("submit");
  }
}
