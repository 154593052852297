import { authGuard, authGuardCharging } from "@/auth/authGuard";
import { permissionGuard } from "@/auth/permissionAuthGuard";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import AuthorizationOverview from "@/views/AuthorizationOverview.vue";
import Error from "@/views/Error.vue";
import NoRoles from "@/views/NoRoles.vue";
import ReportHome from "@/views/ReportHome.vue";
import UserProfile from "@/views/UserProfile.vue";
import PartnerPermissions from "@/views/portal/PartnerUnauthorized.vue";
import Sign from "@/views/report/Sign.vue";
import ThgBillingOverview from "@/views/thg/ThgBillingOverview.vue";
import ThgChargingStationCreate from "@/views/thg/ThgChargingStationCreate.vue";
import ThgChargingStationDetail from "@/views/thg/ThgChargingStationDetail.vue";
import ThgChargingStationOverview from "@/views/thg/ThgChargingStationOverview.vue";
import ThgCheckout from "@/views/thg/ThgCheckout.vue";
import ThgCheckoutWithHint from "@/views/thg/ThgCheckoutWithHint.vue";
import ThgClosing from "@/views/thg/ThgClosing.vue";
import ThgClosingWithHint from "@/views/thg/ThgClosingWithHint.vue";
import ThgContactData from "@/views/thg/ThgContactData.vue";
import ThgContactDataAt from "@/views/thg/ThgContactDataAt.vue";
import ThgCustomerDetails from "@/views/thg/ThgCustomerDetails.vue";
import ThgErrorPage from "@/views/thg/ThgErrorPage.vue";
import ThgFrequentlyAskedQuestions from "@/views/thg/ThgFrequentlyAskedQuestions.vue";
import ThgFrequentlyAskedQuestionsAt from "@/views/thg/ThgFrequentlyAskedQuestionsAt.vue";
import ThgHome from "@/views/thg/ThgHome.vue";
import ThgImpactFactor from "@/views/thg/ThgImpactFactor.vue";
import ThgImpactSelector from "@/views/thg/ThgImpactSelector.vue";
import ThgImpactSelectorOnlyCustomerAccount from "@/views/thg/ThgImpactSelectorOnlyCustomerAccount.vue";
import ThgImpactSelectorOnlyCustomerAccountAndPayout from "@/views/thg/ThgImpactSelectorOnlyCustomerAccountAndPayout.vue";
import ThgImpactSelectorOnlyPayout from "@/views/thg/ThgImpactSelectorOnlyPayout.vue";
import ThgImpactSelectorRetire from "@/views/thg/ThgImpactSelectorRetire.vue";
import ThgImpactSelectorTrees from "@/views/thg/ThgImpactSelectorTrees.vue";
import ThgImpactSelectorWithCustomerAccount from "@/views/thg/ThgImpactSelectorWithCustomerAccount.vue";
import ThgImprint from "@/views/thg/ThgImprint.vue";
import ThgInsuranceCheck from "@/views/thg/ThgInsuranceCheck.vue";
import ThgInviteFriends from "@/views/thg/ThgInviteFriends.vue";
import ThgLogin from "@/views/thg/ThgLogin.vue";
import ThgMeterReadingCreate from "@/views/thg/ThgMeterReadingCreate.vue";
import ThgOverview from "@/views/thg/ThgOverview.vue";
import ThgPayoutConfiguration from "@/views/thg/ThgPayoutConfiguration.vue";
import ThgCustomerAccount from "@/views/thg/ThgCustomerAccount.vue";
import ThgPassword from "@/views/thg/ThgPassword.vue";
import ThgPrivacy from "@/views/thg/ThgPrivacy.vue";
import ThgProducts from "@/views/thg/ThgProducts.vue";
import ThgRegistrationAtImageUpload from "@/views/thg/ThgRegistrationAtImageUpload.vue";
import ThgRegistrationFrontImageUpload from "@/views/thg/ThgRegistrationFrontImageUpload.vue";
import ThgRegistrationImageUpload from "@/views/thg/ThgRegistrationImageUpload.vue";
import ThgSignUp from "@/views/thg/ThgSignUp.vue";
import ThgSuccess from "@/views/thg/ThgSuccess.vue";
import ThgSustainable from "@/views/thg/ThgSustainable.vue";
import ThgSustainableTrees from "@/views/thg/ThgSustainableTrees.vue";
import ThgTerms from "@/views/thg/ThgTerms.vue";
import ThgVehicleClass from "@/views/thg/ThgVehicleClass.vue";
import ThgVehicleDetail from "@/views/thg/ThgVehicleDetail.vue";
import ThgVehicleOverview from "@/views/thg/ThgVehicleOverview.vue";
import ThgYearSelector from "@/views/thg/ThgYearSelector.vue";
import ThgZip from "@/views/thg/ThgZip.vue";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { getLoginRoutes } from "./login.router";
import { SettingRoutes } from "./settings.router";

Vue.use(VueRouter);
const routes: Array<RouteConfig> = [];

const thgRoutes: Array<RouteConfig> = [
  /**
   * THG Screens
   */
  {
    path: "/" + ReportScreenEnum.thghome,
    component: ThgHome,
    name: ReportScreenEnum.thghome
  },
  {
    path: "/" + ReportScreenEnum.thgcustomerdetails,
    component: ThgCustomerDetails,
    name: ReportScreenEnum.thgcustomerdetails
  },
  {
    path: "/" + ReportScreenEnum.thgzip,
    component: ThgZip,
    name: ReportScreenEnum.thgzip
  },
  {
    path: "/" + ReportScreenEnum.thgimpactfactor,
    component: ThgImpactFactor,
    name: ReportScreenEnum.thgimpactfactor
  },
  {
    path: "/" + ReportScreenEnum.thgcontact,
    component: ThgContactData,
    name: ReportScreenEnum.thgcontact
  },
  {
    path: "/" + ReportScreenEnum.thgcontactat,
    component: ThgContactDataAt,
    name: ReportScreenEnum.thgcontactat
  },
  {
    path: "/" + ReportScreenEnum.thgclosing,
    component: ThgClosing,
    name: ReportScreenEnum.thgclosing
  },
  {
    path: "/" + ReportScreenEnum.thgclosingwithhint,
    component: ThgClosingWithHint,
    name: ReportScreenEnum.thgclosingwithhint
  },
  {
    path: "/" + ReportScreenEnum.thgCheckout,
    component: ThgCheckout,
    name: ReportScreenEnum.thgCheckout
  },
  {
    path: "/" + ReportScreenEnum.thgCheckoutWithHint,
    component: ThgCheckoutWithHint,
    name: ReportScreenEnum.thgCheckoutWithHint
  },
  {
    path: "/" + ReportScreenEnum.thgProducts,
    component: ThgProducts,
    name: ReportScreenEnum.thgProducts
  },
  {
    path: "/" + ReportScreenEnum.thgSustainable,
    component: ThgSustainable,
    name: ReportScreenEnum.thgSustainable
  },
  {
    path: "/" + ReportScreenEnum.thgSustainableTrees,
    component: ThgSustainableTrees,
    name: ReportScreenEnum.thgSustainableTrees
  },
  {
    path: "/" + ReportScreenEnum.thgPayoutConfiguration,
    component: ThgPayoutConfiguration,
    name: ReportScreenEnum.thgPayoutConfiguration
  },
  {
    path: "/" + ReportScreenEnum.thgCustomerAccount,
    component: ThgCustomerAccount,
    name: ReportScreenEnum.thgCustomerAccount
  },
  {
    path: "/" + ReportScreenEnum.thgVehicleClass,
    component: ThgVehicleClass,
    name: ReportScreenEnum.thgVehicleClass
  },
  {
    path: "/" + ReportScreenEnum.thgimpactselector,
    component: ThgImpactSelector,
    name: ReportScreenEnum.thgimpactselector
  },
  {
    path: "/" + ReportScreenEnum.thgimpactselectortrees,
    component: ThgImpactSelectorTrees,
    name: ReportScreenEnum.thgimpactselectortrees
  },
  {
    path: "/" + ReportScreenEnum.thgImpactSelectorWithCustomerAccount,
    component: ThgImpactSelectorWithCustomerAccount,
    name: ReportScreenEnum.thgImpactSelectorWithCustomerAccount
  },
  {
    path: "/" + ReportScreenEnum.thgImpactSelectorOnlyPayout,
    component: ThgImpactSelectorOnlyPayout,
    name: ReportScreenEnum.thgImpactSelectorOnlyPayout
  },
  {
    path: "/" + ReportScreenEnum.thgImpactSelectorOnlyCustomerAccount,
    component: ThgImpactSelectorOnlyCustomerAccount,
    name: ReportScreenEnum.thgImpactSelectorOnlyCustomerAccount
  },
  {
    path: "/" + ReportScreenEnum.thgImpactSelectorOnlyCustomerAccountAndPayout,
    component: ThgImpactSelectorOnlyCustomerAccountAndPayout,
    name: ReportScreenEnum.thgImpactSelectorOnlyCustomerAccountAndPayout
  },
  {
    path: "/" + ReportScreenEnum.thgImpactSelectorRetire,
    component: ThgImpactSelectorRetire,
    name: ReportScreenEnum.thgImpactSelectorRetire
  },
  {
    path: "/" + ReportScreenEnum.thgregistration,
    component: ThgRegistrationImageUpload,
    name: ReportScreenEnum.thgregistration
  },
  {
    path: "/" + ReportScreenEnum.thgregistrationat,
    component: ThgRegistrationAtImageUpload,
    name: ReportScreenEnum.thgregistrationat
  },
  {
    path: "/" + ReportScreenEnum.thgregistrationfront,
    component: ThgRegistrationFrontImageUpload,
    name: ReportScreenEnum.thgregistrationfront
  },
  {
    path: "/" + ReportScreenEnum.thgpassword,
    component: ThgPassword,
    name: ReportScreenEnum.thgpassword
  },
  {
    path: "/" + ReportScreenEnum.thgYearSelection,
    component: ThgYearSelector,
    name: ReportScreenEnum.thgYearSelection
  },
  {
    path: "/thgsuccess",
    component: ThgSuccess,
    name: "ThgSuccess"
  },
  {
    path: "/insurance-check",
    component: ThgInsuranceCheck,
    name: "ThgInsuranceCheck"
  },
  {
    path: "/faq",
    component: ThgFrequentlyAskedQuestions,
    name: "ThgFrequentlyAskedQuestions"
  },
  {
    path: "/faqat",
    component: ThgFrequentlyAskedQuestionsAt,
    name: "ThgFrequentlyAskedQuestionsAt"
  },
  {
    path: "/impressum",
    component: ThgImprint,
    name: "ThgImprint"
  },
  {
    path: "/agbs",
    component: ThgTerms,
    name: "ThgTerms"
  },
  {
    path: "/datenschutz",
    component: ThgPrivacy,
    name: "ThgDatenschutz"
  },
  {
    path: "/privacy",
    component: ThgPrivacy,
    name: "ThgPrivacy"
  },
  {
    path: "/vehicle",
    component: ThgVehicleOverview,
    name: "ThgVehicleOverview"
  },
  {
    path: "/vehicle/:thgId",
    component: ThgVehicleDetail,
    name: "ThgVehicleDetail"
  },
  {
    path: "/overview",
    component: ThgOverview,
    name: "ThgOverview"
  },
  {
    path: "/invite",
    component: ThgInviteFriends,
    name: "ThgInviteFriends"
  },
  {
    path: "/billing",
    component: ThgBillingOverview,
    name: "ThgBillingOverview"
  },
  {
    path: "/charging-station",
    component: ThgChargingStationOverview,
    name: "ThgChargingStationOverview",
    beforeEnter: authGuardCharging
  },
  {
    path: "/charging-station/new",
    component: ThgChargingStationCreate,
    name: "ThgChargingStationCreate"
  },
  {
    path: "/charging-station/:chargingStationId",
    component: ThgChargingStationDetail,
    name: "ThgChargingStationDetail"
  },
  {
    path: "/charging-station/:chargingStationId/meter-reading",
    component: ThgMeterReadingCreate,
    name: "ThgMeterReadingCreate"
  },
  {
    path: "/sign/:signRequestId",
    name: "DigitalSignature",
    component: Sign
  },
  {
    path: "/error",
    name: "PartnerContactError",
    component: ThgErrorPage
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: PartnerPermissions
  },

  {
    path: "/no-roles",
    name: "NoRoles",
    component: NoRoles
  },
  {
    path: "/errors",
    name: "Error",
    component: Error
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: ThgSignUp
  },
  {
    path: "/profile",
    name: "Profile",
    component: UserProfile,
    beforeEnter: authGuard
  },
  {
    path: "/profile/:tab",
    name: "ProfileTab",
    component: UserProfile,
    beforeEnter: authGuard
  },
  {
    path: "/permissions",
    name: "Permissions",
    component: AuthorizationOverview
  },
  {
    path: "*",
    component: ReportHome
  }
];

getLoginRoutes(ThgLogin).forEach((route: RouteConfig) => {
  route.path = "/login" + route.path;
  routes.push(route);
});

thgRoutes.forEach(route => {
  routes.push(route);
});

SettingRoutes.forEach((route: RouteConfig) => {
  route.path = "/settings" + route.path;
  routes.push(route);
});

const router = new VueRouter({
  routes,
  // mode: "history",
  base: "/thg/",
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach(permissionGuard);

export default router;
