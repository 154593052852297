var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('layout-report-with-banner', {
    attrs: {
      "isProgressDone": true,
      "showFooter": true,
      "showLoginButton": _vm.showLoginButton
    },
    scopedSlots: _vm._u([{
      key: "banner",
      fn: function fn() {
        return [_c('v-img', {
          staticClass: "hidden-xs-only",
          attrs: {
            "src": _vm.headerImage,
            "height": "550"
          }
        }, [_c('v-overlay', {
          attrs: {
            "value": "true",
            "absolute": ""
          }
        }, [_c('v-container', {
          staticClass: "mx-auto"
        }, [_c('v-row', [_c('v-col', {
          staticClass: "ml-4"
        }, [_c('h2', {
          staticClass: "text-md-h2 text-sm-h3 text-h4 font-weight-medium mt-sm-8 mt-md-0",
          domProps: {
            "innerHTML": _vm._s(_vm.$t(_vm.slogan))
          }
        })])], 1), _c('v-row', {
          staticClass: "mt-8"
        }, [_c('v-col', {
          attrs: {
            "xl": "4",
            "lg": "5",
            "md": "6",
            "xs": "10",
            "sm": "8",
            "cols": "10"
          }
        }, [_c('v-btn', {
          staticClass: "ml-4",
          attrs: {
            "color": _vm.color,
            "id": "start",
            "elevation": "0",
            "block": "",
            "dark": "",
            "x-large": "",
            "data-test-report-start-desktop-only": ""
          },
          on: {
            "click": _vm.start
          }
        }, [_vm._v(" " + _vm._s(_vm.$t(_vm.buttonText)) + " ")])], 1)], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('v-row', {
          attrs: {
            "align": "center",
            "justify": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "md": "6",
            "cols": "12"
          }
        }, [_c('v-card', {
          attrs: {
            "flat": "",
            "max-width": "1200",
            "color": "transparent"
          }
        }, [_c('v-card-text', {
          staticClass: "text-md-h2 text-sm-h3 text-h4 mt-md-12  mt-4  font-weight-medium"
        }, [_c('h1', {
          style: "color:".concat(_vm.partnerColorDarkmode)
        }, [_vm._v(" " + _vm._s(_vm.$t(_vm.title)) + " ")])])], 1), _c('v-card', {
          staticClass: "mt-4",
          attrs: {
            "flat": "",
            "max-width": "800",
            "color": "transparent"
          }
        }, [_vm._l(_vm.items, function (item, i) {
          return _c('v-row', {
            key: i,
            staticClass: "pl-6 "
          }, [_c('v-col', {
            staticClass: "pl-0",
            attrs: {
              "cols": "1"
            }
          }, [_c('v-icon', {
            domProps: {
              "textContent": _vm._s(item.icon)
            }
          })], 1), _c('v-col', {
            attrs: {
              "cols": "11"
            }
          }, [_c('p', [_vm._v(_vm._s(_vm.$t(item.text)))])])], 1);
        }), _c('v-row', {
          staticClass: "ma-2 mt-8"
        }, [_c('login-register-button', {
          attrs: {
            "showRegisterButton": false,
            "htmlClass": "mr-2"
          }
        }), _c('v-btn', {
          attrs: {
            "color": _vm.color,
            "id": "start",
            "elevation": "0",
            "dark": "",
            "large": "",
            "data-test-report-start": ""
          },
          on: {
            "click": _vm.start
          }
        }, [_vm._v(" " + _vm._s(_vm.$t(_vm.buttonText)) + " ")])], 1)], 2)], 1), _c('v-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('v-img', {
          staticClass: "ma-8 hidden-sm-and-down",
          attrs: {
            "src": _vm.imageSrc
          }
        })], 1)], 1), _c('query-params-list')];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }