


























































import { NotificationHandler } from "@/lib/notification/NotificationHandler";
import { extendetPhoneRule } from "@/lib/rules/contactRule";
import { INotification } from "@/store/interface/notification.interface";
import { NotificationModule } from "@/store/modules/notification.store";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class Notifications extends Vue {
  informationsChecked = false;
  isLoading = false;

  /** Value to pass to notification.action */
  fieldValue = "";

  get isSmallDisplay(): string {
    if (this.smallDisplay) {
      return "12";
    } else {
      return "auto";
    }
  }

  get smallDisplay(): boolean {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return true;
      case "sm":
        return true;
      case "md":
        return false;
      case "lg":
        return false;
      case "xl":
        return false;
    }
    return false;
  }

  async mounted() {
    if (!NotificationModule.isNotificationsLoaded) {
      try {
        NotificationModule.setIsNotificationsLoaded(true);
        await NotificationHandler.check();
      } catch (error) {
        this.$log.error(error);
      }
    }
  }

  get isNotification() {
    return NotificationModule.isNotification;
  }

  get notifications() {
    return NotificationModule.notifications;
  }

  popNotification(index: number) {
    NotificationModule.popNotification(index);
  }

  async processNotification(notification: INotification, index: number) {
    if (notification.action) {
      try {
        this.isLoading = true;

        // Check if fieldValue is valid before passing it to the action function
        const fieldValue = this.$refs.alertField && (this.$refs.alertField as any)[0].hasError ? null : this.fieldValue;
        await notification.action(fieldValue);

        if (notification.closeOnSuccess) {
          this.popNotification(index);
        }
      } catch (error) {
        this.$log.error(error);
      } finally {
        this.isLoading = false;
      }
    }

    if (notification.location) {
      this.$router.push(notification.location);
      this.popNotification(index);
    }
  }

  getRules(notification: INotification) {
    const rules = [];

    if (notification.textFieldType === "tel") {
      rules.push(extendetPhoneRule());
    }

    return rules;
  }
}
