import { BillingTypeEnum } from "@/lib/enum/billingType.enum";
import { Filter, FilterTypes, IsFilterable, FilterConfig } from "@/lib/filterable";
import {
  ThgBillingViewmodelGen,
  ThgTimeStampViewModelGen,
  ThgAccountingRecordItemGen,
  ThgAccountingRecordItemViewmodelGen,
  ThgBillingPdfDataGen
} from "@/services/thg/v1/data-contracts";
import { Timestamp } from "./timestamp.entity";
import billingService from "@/services/thg/services/billingService";
import { handleError } from "@/lib/utility/handleError";
import { IEntity } from "@/lib/utility/data/entity.interface";
import { BillingDataAccessLayer } from "@/store/modules/access-layers/billing.access-layer";
import { AccountingRecordItem } from "@/models/accounting-record.entity";
import { ThgBillingPdfData } from "./thg-billing-pdf-data.entity";

@IsFilterable
class BillingBase implements ThgBillingViewmodelGen, Partial<IEntity<IBilling>> {
  @FilterConfig({
    type: FilterTypes.NUMBER,
    displayName: "objects.billing.billingNumber"
  })
  billingNumber: number;

  @FilterConfig({
    type: FilterTypes.ENUM,
    config: { items: Object.values(BillingTypeEnum) },
    displayName: "objects.billing.type"
  })
  type: BillingTypeEnum;

  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.billing.referenceIds"
  })
  referenceIds?: string[] | undefined;

  accountRecord: ThgAccountingRecordItemGen[];

  @FilterConfig({
    type: ThgBillingPdfData
  })
  pdfBilling: ThgBillingPdfDataGen[];

  @FilterConfig({
    type: Timestamp
  })
  timestamp: ThgTimeStampViewModelGen;

  @FilterConfig({
    type: FilterTypes.NUMBER,
    displayName: "objects.billing.accountNumber"
  })
  accountNumber: number;

  id: string;

  @FilterConfig({
    type: FilterTypes.BOOLEAN,
    displayName: "objects.billing.isPublished"
  })
  isPublished: boolean;

  thgId?: string | undefined;

  @FilterConfig({ type: FilterTypes.OBJECT_ID, displayName: "objects.billing.partnerId" })
  partnerId?: string | undefined;

  @FilterConfig({ type: FilterTypes.OBJECT_ID, displayName: "objects.billing.userId" })
  userId: string;

  /**
   * Sums up the total amount of all account records for a billing
   *
   *
   * @param billing
   * @returns
   */
  @FilterConfig({
    type: FilterTypes.NUMBER,
    displayName: "components.thg.thgBillingCsvSelection.accountRecordTotal"
  })
  get accountRecordTotal() {
    /**
     * The total should should reflect the total pay out to the billed entity which would be how much money remains in their account after all booking has been processed. So I sum up how much money goes into someones account and I deduct how much money is taken out.
     *
     * Lets say I have account number 0, wkdthg has account 1, and trees are account 2 Now I am supposed to get 100 for my Vehicle A and 100 for Vehicle B, but I give away 50 to trees.
     *
     * then the accounting statements would be
     * 1 -> 0 100 for vehicle A
     * 1 -> 0 100 for vehicle B
     * 0 -> 2 50 for trees for vehicle B
     *
     * hence i calculate 100+100-50
     */
    let totalSum = 0;

    for (const accountRecord of this.accountRecord || []) {
      // Add the amount if it is booked into the billed account
      if (accountRecord.account === this.accountNumber) {
        totalSum += accountRecord.amount;
      } else {
        // Remove the amount if it is booked out of the billed account
        totalSum -= accountRecord.amount;
      }
    }
    return Math.round(totalSum * 100) / 100;
  }

  constructor(billing?: Partial<ThgBillingViewmodelGen | IBilling>) {
    this.billingNumber = billing?.billingNumber || 0;
    this.type = billing?.type as BillingTypeEnum;
    this.referenceIds = billing?.referenceIds || [];
    this.accountRecord = billing?.accountRecord?.map(r => new AccountingRecordItem(r)) || [];
    this.pdfBilling = billing?.pdfBilling || [];
    this.timestamp = billing?.timestamp as ThgTimeStampViewModelGen;
    this.accountNumber = billing?.accountNumber || -1;
    this.id = billing?.id || "";
    this.isPublished = billing?.isPublished || false;
    this.thgId = billing?.thgId || "";
    this.partnerId = billing?.partnerId || "";
    this.userId = billing?.userId || "";
  }

  private map(billing?: Partial<ThgBillingViewmodelGen>) {
    this.billingNumber = billing?.billingNumber || 0;
    this.type = billing?.type as BillingTypeEnum;
    this.referenceIds = billing?.referenceIds || [];
    this.accountRecord = billing?.accountRecord?.map(r => new AccountingRecordItem(r)) || [];
    this.pdfBilling = billing?.pdfBilling || [];
    this.timestamp = billing?.timestamp as ThgTimeStampViewModelGen;
    this.accountNumber = billing?.accountNumber || -1;
    this.id = billing?.id || "";
    this.isPublished = billing?.isPublished || false;
    this.thgId = billing?.thgId || "";
    this.partnerId = billing?.partnerId || "";
    this.userId = billing?.userId || "";
  }

  async fetch() {
    await billingService
      .getBilling(this.billingNumber)
      .then(b => this.map(b))
      .catch(handleError);

    BillingDataAccessLayer.set(this);

    return this;
  }

  async fetchPdf() {
    if (!this.pdfBilling?.length) {
      return this;
    }

    const { url } = await billingService.getPdf(this.billingNumber);
    this.pdfBilling[0].url = url;

    BillingDataAccessLayer.set(this);

    return this;
  }

  async updateAccountingItem(accountingItem: ThgAccountingRecordItemViewmodelGen[]) {
    await billingService.updateAccountingItem(this.billingNumber, accountingItem);
    await this.fetch();
  }
}
type IBilling = BillingBase;
const Billing = Filter.createForClass(BillingBase);

export { IBilling, Billing };
