var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "800",
      "fullscreen": _vm.$vuetify.breakpoint.xsOnly,
      "transition": "dialog-bottom-transition"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "loading": _vm.loading,
            "icon": ""
          },
          on: {
            "click": _vm.openDialog
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-pencil")])], 1)];
      }
    }]),
    model: {
      value: _vm.isDialogActive,
      callback: function callback($$v) {
        _vm.isDialogActive = $$v;
      },
      expression: "isDialogActive"
    }
  }, [_vm.updateModel ? _c('v-card', [_c('v-card-title', [_c('span', [_vm._v(_vm._s(_vm.$t("components.thg.ThgQuoteDetailInformationContractCard.title")))]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey lighten-1"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("mdi-close")])], 1)], 1), _c('v-card-text', [_c('debug', [_vm._v(_vm._s(_vm.updateModel))]), _c('v-form', {
    ref: "form",
    model: {
      value: _vm.isValid,
      callback: function callback($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('v-text-field', {
    staticClass: "px-0",
    attrs: {
      "rules": [_vm.requiredRule],
      "label": _vm.$t('components.thg.ThgQuoteDetailInformationContractCard.changeYear'),
      "type": "number",
      "required": "",
      "outlined": "",
      "data-test-revenue": ""
    },
    model: {
      value: _vm.updateModel.year,
      callback: function callback($$v) {
        _vm.$set(_vm.updateModel, "year", _vm._n($$v));
      },
      expression: "updateModel.year"
    }
  }), _c('v-subheader', [_vm._v(_vm._s(_vm.$t("report.thgPayoutConfiguration.title")))]), _c('v-text-field', {
    staticClass: "px-0",
    attrs: {
      "rules": [_vm.minZeroNumberRule],
      "label": _vm.$t('components.thg.ThgQuoteDetailInformationContractCard.changePayout'),
      "type": "number",
      "required": "",
      "outlined": "",
      "data-test-revenue": ""
    },
    model: {
      value: _vm.updateModel.payoutConfiguration.revenue,
      callback: function callback($$v) {
        _vm.$set(_vm.updateModel.payoutConfiguration, "revenue", _vm._n($$v));
      },
      expression: "updateModel.payoutConfiguration.revenue"
    }
  }), _c('v-combobox', {
    attrs: {
      "items": [false, true],
      "label": _vm.$t('components.thg.ThgQuoteDetailInformationContractCard.fix'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "data-test-fixed": ""
    },
    model: {
      value: _vm.updateModel.payoutConfiguration.isFixed,
      callback: function callback($$v) {
        _vm.$set(_vm.updateModel.payoutConfiguration, "isFixed", $$v);
      },
      expression: "updateModel.payoutConfiguration.isFixed"
    }
  }), _c('div', {
    key: _vm.promoUpdateKey
  }, [_c('v-subheader', [_vm._v(" " + _vm._s(_vm.$t("common.nouns.promotion")) + " "), _c('v-spacer'), _vm.updateModel.promotionConfiguration ? _c('v-btn', {
    attrs: {
      "x-small": "",
      "text": ""
    },
    on: {
      "click": _vm.removePromo
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("remove")) + " ")]) : _c('v-btn', {
    attrs: {
      "x-small": "",
      "text": ""
    },
    on: {
      "click": _vm.addPromo
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("add")) + " ")])], 1), _vm.updateModel.promotionConfiguration ? _c('v-combobox', {
    attrs: {
      "items": [_vm.PromotionTypeEnum.FIRST_PURCHASE],
      "label": _vm.$t('objects.promotion.type'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "data-test-fixed": ""
    },
    model: {
      value: _vm.updateModel.promotionConfiguration.type,
      callback: function callback($$v) {
        _vm.$set(_vm.updateModel.promotionConfiguration, "type", $$v);
      },
      expression: "updateModel.promotionConfiguration.type"
    }
  }) : _vm._e(), _vm.updateModel.promotionConfiguration ? _c('v-text-field', {
    staticClass: "px-0",
    attrs: {
      "rules": [_vm.minZeroNumberRule],
      "label": _vm.$t('objects.promotion.value'),
      "type": "number",
      "min": 0,
      "required": "",
      "outlined": "",
      "data-test-revenue": ""
    },
    model: {
      value: _vm.updateModel.promotionConfiguration.value,
      callback: function callback($$v) {
        _vm.$set(_vm.updateModel.promotionConfiguration, "value", _vm._n($$v));
      },
      expression: "updateModel.promotionConfiguration.value"
    }
  }) : _vm._e()], 1)], 1), _c('v-subheader', [_vm._v(_vm._s(_vm.$t("report.thgChargingStation.meterReading.title")))]), _vm.thg.meterReading ? _c('v-form', {
    ref: "form2",
    model: {
      value: _vm.isValid,
      callback: function callback($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('v-text-field', {
    staticClass: "px-0",
    attrs: {
      "rules": [_vm.requiredRule],
      "label": _vm.$t('components.thg.ThgChargingStationMeterReadingTable.startDate'),
      "required": "",
      "outlined": "",
      "data-test-start-date": "",
      "disabled": ""
    },
    model: {
      value: _vm.thg.meterReading.startDate,
      callback: function callback($$v) {
        _vm.$set(_vm.thg.meterReading, "startDate", $$v);
      },
      expression: "thg.meterReading.startDate"
    }
  }), _c('v-text-field', {
    staticClass: "px-0",
    attrs: {
      "rules": [_vm.requiredRule],
      "label": _vm.$t('components.thg.ThgChargingStationMeterReadingTable.endDate'),
      "required": "",
      "outlined": "",
      "data-test-end-date": "",
      "disabled": ""
    },
    model: {
      value: _vm.thg.meterReading.endDate,
      callback: function callback($$v) {
        _vm.$set(_vm.thg.meterReading, "endDate", $$v);
      },
      expression: "thg.meterReading.endDate"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": [_vm.requiredRule],
      "label": _vm.$t('components.thg.ThgChargingStationMeterReadingTable.amountInKwh'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "data-test-amount-in-kwh": "",
      "disabled": ""
    },
    model: {
      value: _vm.thg.meterReading.amountInKwh,
      callback: function callback($$v) {
        _vm.$set(_vm.thg.meterReading, "amountInKwh", _vm._n($$v));
      },
      expression: "thg.meterReading.amountInKwh"
    }
  })], 1) : _vm._e()], 1), _c('v-card-actions', [_c('v-btn', {
    staticClass: "ma-4",
    attrs: {
      "color": "success",
      "disabled": !_vm.isValid,
      "loading": _vm.loading
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" " + _vm._s("Save") + " ")])], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }